import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import SEO from '../components/SEO';
import Header from '../components/Header';
import Layout from '../components/Layout';
import Credits from '../components/utils/Credits';
import '../styles/components/terms.scss';

class Terms extends React.Component {

	render() {

		return (
			<>
				<StaticQuery 
					query={graphql`
						query TermsQuery {
							  wp {
							    generalSettings {
							      title
							      description
							    }
							    siteSettings {
							      settings {
							        address
							        email
							        phone
							      }
							    }
							  }
							  wpPage(id: {eq: "cG9zdDo0NDY="}) {
							    id
							    title
							    content
							  }
							}
					`}
					render={(terms) => (
						<>
							<SEO
								bodyClasses="bg:grey"
								siteTitle={ terms.wp.generalSettings.title }
								siteDescription={ terms.wp.generalSettings.description }
								title={ terms.wpPage.title } 
								parentPage="terms"
							/>
							<Layout>
								<Header parentPage={ 'terms' } />
									<article className={ 'terms text:section' }>
										<div>
											<h2>{ terms.wpPage.title }</h2>
											<div className={ 'copy' } dangerouslySetInnerHTML={{ __html: terms.wpPage.content }} />

											<Credits />
										</div>
									</article>
							</Layout>
						</>
					)}
				/>
			</>
		);

	}

}

export default Terms;