import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'gatsby';
import '../../styles/components/credits.scss';

export default function Credits( props ) {
	const [ isVisible, setIsVisible ] = useState(false);

	return (
		<>
			<p className="text:credits text:tracked uppercase credits"><span>&copy;</span>{ new Date().getFullYear() } Mooradian Studio. <Link to={ '/terms' }>Terms</Link>. 
			<a 	onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				setIsVisible(!isVisible);
			}}
				href="#">
				{ isVisible === false && (
					<> Credit</>
				)}

				{ isVisible === true &&  (
					<span> Design by <a onClick={(e) => {e.stopPropagation()}} target="_blank" rel="noreferrer" href="https://polytechnic.works">Polytechnic</a></span>
				)}
				</a> 
			</p>
		</>
	);

}